import { urls } from "~/api/config";
import { getCachedData } from "~/utils/cached-data";
import type {
  IMainSlide,
  IPublicationsResponse,
  ITicker,
  IArticle,
  IBrandsResponse,
  IGiftPackagesResponse, ICatalogSectionItem, ISpecialOffersResponse, IPrivateLabelsResponse,
} from "~/typings/api/content";

export default () => {
  const commonParams = {
    lazy: true,
    getCachedData: getCachedData,
  };

  const requestMainSliderSlides = () =>
    useTransport<IMainSlide[]>(urls.content.mainSlides, commonParams);

  const requestTicker = () => useTransport<ITicker, ITicker>(urls.content.ticker, commonParams);

  const requestBrands = () => useTransport<IBrandsResponse, IBrandsResponse>(urls.content.brands, commonParams);

  const requestGiftPackages = () => useTransport<IGiftPackagesResponse, IGiftPackagesResponse>(urls.content.giftPackages, commonParams);

  const requestCategories = () => useTransport<ICatalogSectionItem[], ICatalogSectionItem[]>(urls.content.categories, commonParams);

  const requestSpecialOffers = () => useTransport<ISpecialOffersResponse, ISpecialOffersResponse>(urls.content.specialOffers, commonParams);

  const requestExclusiveWithUs = () => useTransport<IPrivateLabelsResponse, IPrivateLabelsResponse>(urls.content.exclusiveWithUs, commonParams);

  const requestArticle = (id: string) => useTransport<IArticle>(
    `${urls.content.article}/${id}`,
    {
      ...commonParams,
      key: `article-${id}`,
    },
  );

  const requestPublications = (body?: {
    exclude?: number[];
    limit?: number;
    offset?: number;
  }) => useTransport<IPublicationsResponse>(urls.content.publications, {
    method: "POST",
    body,
    lazy: true,
  });

  return {
    requestMainSliderSlides,
    requestTicker,
    requestPublications,
    requestArticle,
    requestBrands,
    requestGiftPackages,
    requestCategories,
    requestSpecialOffers,
    requestExclusiveWithUs,
  };
};
